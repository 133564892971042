import React, { useState, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import $ from 'jquery';
import store from 'store';
import Badge from '../../components/Badge';
import Binder from '../../lib/Binder';
import Button from '../../components/Button';
import Controller from '../../mixins/Controller';
import Form from '../../components/Form';
import GroupBadge from '../../components/GroupBadge';
import JobSummary from '../views/JobSummary';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import InspectionController from '../controllers/InspectionController';
import Authentication from '../services/authentication';
import SAPService from '../services/SAPService';
import ServiceLevelHelper from '../services/ServiceLevelHelper';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import Utils from '../utility/Utils';
import QRSticker from '../components/QRSticker';
import AppConstants from '../utility/AppConstants';
import { useHistory } from 'react-router-dom';
import Table from '../../components/Table';
import Searchers from '../utility/Searchers';
import Grid from '../../components/Grid';
import Tab from '../../components/Tab';
import Tabs from '../../components/Tabs';
import UTScopePage from './UTScopePage';
import ActionIcon from '../../components/ActionIcon';

const JobFilter = (props) => {

  const controller = props.controller;
  const binder = props.binder;
  const spanLockRequired = controller.state.selectedJob && controller.state.selectedJob.canLockForInspection
    ? <span className='field-hint job-not-locked'><i className='fa fa-unlock' />&nbsp;Job is not locked</span>
    : null;
  const spanCompleted = controller.state.selectedJob && controller.state.selectedJob.isComplete
    ? <span className='field-hint err-msg'>Job is complete</span>
    : null;
  const spanIsWaiting = controller.state.selectedJob && controller.state.selectedJob.isWaiting && !controller.state.selectedJob.isComplete
    ? <span className='field-hint job-waiting'><i className='fa fa-clock' />&nbsp;Job is waiting</span>
    : null;

  return (
    <div className={Utils.mergeClassFrom(props.className, '')}>
      <div>
        <Form.AutoComplete bind={binder.bind('selectedJob')} label='Jobs' prependLabelIcon='fa-filter' placeholder='Select a job' search={controller.jobSearch()} />
      </div>
    </div>
  );
}

const GroupFilter = (props) => {

  const renderGroups = (fnOnClick, groupsValue) => {
    if (!groupsValue) {
      return 'No Groups';
    }

    return groups.map(group => {
      const variant = (group.selected) ? 'danger' : 'secondary';
      const groupDisplay = group.value.groupName ? group.value.groupName : group.value.groupNumber;
      return <CSSTransition classNames='fade' timeout={{ enter: 300, exit: 300 }}><GroupBadge key={group.value.groupNumber} variant={variant} className={'mr-1'} onClick={() => { fnOnClick(group.value.groupNumber) }} dataToggle="tooltip" dataPlacement="top" title={group.value.groupName}>{groupDisplay}</GroupBadge></CSSTransition>;
    });
  }

  const handleGroupClick = (groupNumber) => {
    props.controller.toggleGroupSelection(groupNumber);
  }

  const clearGroupSelection = () => {
    props.controller.clearGroupSelection();
  }

  const controller = props.controller;
  const groups = controller.state.groups;
  const binder = props.binder;
  const groupView = groups.length <= 10
    ? <TransitionGroup component='div' transitionName='grow-shrink' transitionEnterTimeout={300} transitionLeaveTimeout={200} id='group-badges'>
      {renderGroups(handleGroupClick, groups)}
    </TransitionGroup>
    : <Form.MultiSelect id='group-multiselect' className='groupNumber-select' placeholder='Click here to select Groups' bind={binder.bind('multiSelectGroups')} search={Searchers.groupNumberandNameSearch(controller.state.selectedJob && controller.state.selectedJob.id)} style={{ height: 'auto' }} />
  return (
    <div className='col-md-12'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='d-flex'>
            <div className='mr-2 mt-2'><Form.Label name='Groups' prependLabelIcon='fa-filter ' /></div>
            <div className='mt-2'><GroupBadge variant='danger' pill={false} onClick={clearGroupSelection} >Clear Group Selection</GroupBadge></div>
          </div>
          {groupView}
        </div>
      </div>
    </div>
  );
}

const ServiceLevelEntry = (props) => {
  const serviceLevel = props.serviceLevel;
  const serviceLevelName = serviceLevel.serviceLevelName;
  const levelDate = DateTimeFormatter.formatDate(serviceLevel.levelDate);

  if (levelDate) {
    return <div>{serviceLevelName} &ndash; {levelDate}</div>;
  }
  return <div>{serviceLevelName} &ndash; No Prev Svc History</div>;
}

class AssetRow extends React.Component {


  toggleSelection = (asset) => {
    if (this.props.controller.isAssetSelectable(asset)) {
      this.props.controller.toggleSelection(asset);
    }
  }

  getStatusElement = (asset) => {
    if (!asset.serviceOrderNumber) {
      return <Table.Data className='status-column no-service-order-number' title='No SAP SO # yet'><i className='far fa-circle' /></Table.Data>;
    }

    else if (asset.isWaitingForReapplyServiceLevels) {
      return <Table.Data className='status-column asset-active' title='Waiting for Reapply Service Levels to complete'><i className='fas fa-clock asset-waiting-reapply-service-levels' /></Table.Data>;
    }
    else if (asset.isWaitingForLockForInspection) {
      return <Table.Data className='status-column asset-active' title='Waiting for Lock for Inspection request to complete'><i className='fas fa-clock asset-waiting-lock-for-inspection' /></Table.Data>;
    }
    else {
      switch (asset.sapStatus) {
        case AppConstants.AssetSAPStatus.CRTD:
          return <Table.Data className='status-column asset-active' title=''><i className='far fa-clock' /></Table.Data>;

        case AppConstants.AssetSAPStatus.REL:
        case AppConstants.AssetSAPStatus.PCNF:
          return <Table.Data className='status-column asset-active' title='Inspection can be performed'><i className='fas fa-cogs' /></Table.Data>;

        case AppConstants.AssetSAPStatus.TECO:
        case AppConstants.AssetSAPStatus.CLSD:
        case AppConstants.AssetSAPStatus.LKD:
          return <Table.Data className='status-column locked' title='No longer active on this job'><i className='fas fa-lock' /></Table.Data>;

        default:
          return <Table.Data className='status-column no-service-order-number' title='No SAP SO # yet'><i className='fas fa-circle' /></Table.Data>;
      }
    }
  }

  render = () => {
    var controller = this.props.controller;
    var asset = this.props.asset;
    var className = 'asset';

    if (!this.props.controller.isAssetSelectable(asset)) {
      className += ' disabled';
    }
    else if (asset.selected) {
      className += ' selected';
    }
    var testStatus = asset.lastTestResult && asset.lastTestResult.isActive
      ? <span>{asset.lastTestResult.status}<div>{asset.lastTestResult.testedBy} {DateTimeFormatter.formatDate(asset.lastTestResult.testDate)}</div></span>
      : <span>{asset.lastTestResult.status}</span>;
    var statusIcon = controller.getStatusAssetsIcon([asset.lastTestResult], [asset]);
    var assetStatus = asset.assetStatus;
    var statusColourClass = ' status' + asset.assetStatus; // e.g.: statusScrapped
    if (assetStatus === 'Weld Repair' || assetStatus === 'Failed Inspection')
      statusColourClass = 'asset-status statusWeldRepair'
    if (assetStatus === 'OS/Machining Repair') {
      statusColourClass = 'asset-status statusOSRepair'
    }
    if (asset.rejectReason) {
      if (assetStatus != 'Passed')
        assetStatus += ' – ' + asset.rejectReason;
    }
    if (statusColourClass === ' statusScrapped' || statusColourClass === ' statusWeldRepair') {
      className += statusColourClass;
    }

    var shippedBadge = asset.hasShipped ? <Badge variant='info'>S</Badge> : null;
    var tests = ServiceLevelHelper.findDistinctTests(asset.serviceLevels);
    var anyInConflict = tests.some(test => ServiceLevelHelper.hasTestsInConflict(asset.serviceLevels, test));
    var isSAPFacility = SAPService.isSAPFacility();
    var assetServiceLevels = asset.serviceLevels.filter(serviceLevel => serviceLevel.performed);
    var serviceLevels = assetServiceLevels.length
      ? <div>{assetServiceLevels.map(serviceLevel => <ServiceLevelEntry key={serviceLevel.serviceLevelId} serviceLevel={serviceLevel} />)}</div>
      : null;
    var billOfMaterial = isSAPFacility && controller.getBillOfMaterial(assetServiceLevels.length && assetServiceLevels[0]);
    var billOfMaterialCtl = (billOfMaterial === 'No Bill of Material')
      ? <i>{billOfMaterial}</i>
      : billOfMaterial;
    var groupBadge = <Badge variant='info' dataToggle="tooltip" dataPlacement="top" title={asset.groupName}>{asset.groupNumber}</Badge>;

    // in an SAP facility, display the Service Order Number in the same cell as the Primary Serial Number
    var serialNumberCtl = <span>
      <Form.Link to={'/assetdetail/' + asset.assetId} value={asset.primarySerialNumber} />  &nbsp;
      {isSAPFacility && <span className='sap-token'>{(asset.serviceOrderNumber || 'no SAP SO #')}</span>}
    </span>;
    var attachmentBadges = [];
    if (asset.inspectionAttachments && asset.inspectionAttachments.files.length > 0) {
      var count = 0;
      for (var a = 0; a < asset.inspectionAttachments.files.length; a++) {
        if (AppConstants.imgFileTypes.includes(asset.inspectionAttachments.files[a].fileName.split('.')[1].toLowerCase())) {
          count = count + 1;
          var hoverClass = count % 6 == 0 ? 'hover-img-right' : 'hover-img';
          attachmentBadges.push(<a><img className="assetimgsmall" src={asset.inspectionAttachments.files[a].imgUrl} alt="image" /><div className={hoverClass}>{a + 1}<span><img src={asset.inspectionAttachments.files[a].imgUrl} alt="image" height="100" /></span></div></a>);
        }
      }
    }

    var statusElement = isSAPFacility && this.getStatusElement(asset);
    return (
      <Table.Row id={asset.assetId} onClick={() => { this.toggleSelection(asset); }} asset={asset} isSelected={asset.selected} >
        {statusElement}
        <Table.Data className={className}>{groupBadge} {shippedBadge}</Table.Data>
        <Table.Data className={className}>{serialNumberCtl}</Table.Data>
        <Table.Data className={className}>{asset.equipmentNumber}</Table.Data>
        <Table.Data className={className}>{asset.familyCode}</Table.Data>
        <Table.Data className={className}>{asset.size}</Table.Data>
        <Table.Data className={className}>{asset.manufacturer}</Table.Data>
        <Table.Data className={className}>{asset.cwp}</Table.Data>
        <Table.Data className={className}>{statusIcon} <span className='test'>{testStatus}</span></Table.Data>
        <Table.Data className={className + (anyInConflict ? ' service-level-in-conflict' : '')}>{serviceLevels}</Table.Data>
        {isSAPFacility && <Table.Data className={className}>{billOfMaterialCtl}</Table.Data>}
        <Table.Data className={className} style={{ whiteSpace: 'nowrap' }}>{asset.description}</Table.Data>
        <Table.Data className={className}>{<span className={statusColourClass}>{assetStatus}</span>}</Table.Data>
        {/* <Table.Data className={className}><div id='divattimgs'>{attachmentBadges.length > 0 ? attachmentBadges.map(att => att) : null}</div></Table.Data> */}
      </Table.Row>
    );
  }
}

const Results = (props) => {

  const handleRowClick = (assets) => {
    if (assets && assets.length) {
      const selectableAssets = assets.filter(asset => {
        if (props.controller.isAssetSelectable(asset)) {
          return asset;
        }
      });
      props.controller.toggleSelection(selectableAssets);
    }
    else {
      props.controller.selectNone();
    }
  }

  const getRowProps = (row) => {
    const rowProps = {};
    const selectedGroups = props.controller.getSelectedGroups();
    const selectedGroupNumbers = [...selectedGroups.map(group => group.value.groupNumber)];
    if (!selectedGroupNumbers.includes(row.original.groupNumber) && selectedGroupNumbers.length) {
      rowProps.className = 'd-none';
    }
    if (row.original.selected) {
      rowProps.isSelected = true;
    }
    else {
      rowProps.isSelected = false;
    }
    if (!props.controller.isAssetSelectable(row.original)) {
      rowProps.canSelect = false;
    }
    return rowProps;
  }

  const getClassName = (asset) => {
    let className = 'asset';
    const assetStatus = asset.assetStatus;
    if (!controller.isAssetSelectable(asset)) {
      className += ' disabled';
    }
    let statusColourClass = ' status' + assetStatus; // e.g.: statusScrapped
    if (assetStatus === 'Weld Repair' || assetStatus === 'Failed Inspection') {
      statusColourClass = 'asset-status statusWeldRepair'
    }
    if (assetStatus === 'OS/Machining Repair') {
      statusColourClass = 'asset-status statusOSRepair'
    }
    if (statusColourClass === ' statusScrapped' || statusColourClass === ' statusWeldRepair') {
      className += statusColourClass;
    }
    return className;
  }

  const controller = props.controller;
  const showSAPStatus = SAPService.isSAPFacility();
  const data = props.controller.state.inspectionJob.assets;
  const selectedData = data.filter(d => d.selected);
  const selectionType = useMemo(() => {
    let type = 'none';
    if (data.length !== 0 && selectedData.length !== 0) {
      if (data.length === selectedData.length) {
        type = 'all';
      }
      else if (selectedData.length > 0) {
        type = 'some';
      }
      else {
        type = 'none';
      }
    }
    return type;
  }, [data.length, selectedData.length]);
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'sapStatus',
        isVisible: showSAPStatus,
        Cell: cellInfo => {
          if (!cellInfo.row.original.serviceOrderNumber) {
            return <span className='status-column no-service-order-number' title='No SAP SO # yet'><i className='far fa-circle' /></span>;
          }

          else if (cellInfo.row.original.isWaitingForReapplyServiceLevels) {
            return <span className='status-column asset-active' title='Waiting for Reapply Service Levels to complete'><i className='fas fa-clock asset-waiting-reapply-service-levels' /></span>;
          }
          else if (cellInfo.row.original.isWaitingForLockForInspection) {
            return <span className='status-column asset-active' title='Waiting for Lock for Inspection request to complete'><i className='fas fa-clock asset-waiting-lock-for-inspection' /></span>;
          }
          else {
            switch (cellInfo.row.original.sapStatus) {
              case AppConstants.AssetSAPStatus.CRTD:
                return <span className='status-column asset-active' title=''><i className='far fa-clock' /></span>;

              case AppConstants.AssetSAPStatus.REL:
              case AppConstants.AssetSAPStatus.PCNF:
                return <span className='status-column asset-active' title='Inspection can be performed'><i className='fas fa-cogs' /></span>;

              case AppConstants.AssetSAPStatus.TECO:
              case AppConstants.AssetSAPStatus.CLSD:
              case AppConstants.AssetSAPStatus.LKD:
                return <span className='status-column locked' title='No longer active on this job'><i className='fas fa-lock' /></span>;

              default:
                return <span className='status-column no-service-order-number' title='No SAP SO # yet'><i className='fas fa-circle' /></span>;
            }
          }
        }
      },
      {
        Header: 'Group',
        accessor: 'groupNumber',
        Cell: cellInfo => {
          const badge = cellInfo.cell.value
            ? <Badge className='note-badge'>{cellInfo.cell.value}</Badge>
            : null
          const shipped = cellInfo.row.original.hasShipped
            ? <Badge className='text-danger ml-1 font-weight-bold' variant='info'>S</Badge>
            : null
          return <span>{badge}{shipped}</span>
        }
      },
      {
        Header: 'Primary Serial',
        accessor: 'primarySerialNumber',
        sortType: 'basic',
        Cell: (cellInfo) => {
          const isChanged = cellInfo.row.original.ModifiedAssetId != Utils.emptyGuid;
          const display = <div>
            <Form.Link className='mr-1' to={'/assetdetail/' + cellInfo.row.original.assetId} value={cellInfo.cell.value} />
            {showSAPStatus && <span className='sap-token'>{( cellInfo.row.original.serviceOrderNumber != null ? (<span style={{color : isChanged ? "#00008B" : "none" , fontWeight : isChanged ? "bold" : "none" }}> {cellInfo.row.original.serviceOrderNumber} </span>) : 'no SAP SO #')}</span>}
          </div>
          return display;
        }
      },
      {
        Header: 'Equipment #',
        accessor: 'equipmentNumber',
        Cell: (cellInfo) => {
          const className = getClassName(cellInfo.row.original);
          return <span className={className}>{cellInfo.cell.value}</span>
        }
      },
      {
        Header: 'Family Code',
        accessor: 'familyCode',
        Cell: (cellInfo) => {
          const className = getClassName(cellInfo.row.original);
          return <span className={className}>{cellInfo.cell.value}</span>
        }
      },
      {
        Header: 'Size',
        accessor: 'size',
        Cell: (cellInfo) => {
          const className = getClassName(cellInfo.row.original);
          return <span className={className}>{cellInfo.cell.value}</span>
        }
      },
      {
        Header: 'Mfg',
        accessor: 'manufacturer',
        Cell: (cellInfo) => {
          const className = getClassName(cellInfo.row.original);
          return <span className={className}>{cellInfo.cell.value}</span>
        }
      },
      {
        Header: 'Pressure',
        accessor: 'cwp',
        Cell: (cellInfo) => {
          const className = getClassName(cellInfo.row.original);
          return <span className={className}>{cellInfo.cell.value}</span>
        }
      },
      {
        Header: 'Test Status',
        accessor: 'lastTestResult.status',
        Cell: cellInfo => {
          const className = getClassName(cellInfo.row.original);
          const cellValue = cellInfo.row.original.lastTestResult;
          const testStatus = cellValue && cellValue.isActive
            ? <span>{cellValue.status}<div>{cellValue.testedBy} {DateTimeFormatter.formatDate(cellValue.testDate)}</div></span>
            : <span>{cellValue.status}</span>;
          const statusIcon = controller.getStatusAssetsIcon([cellValue], [cellInfo.row.original]);
          const display = <span className={className}>{statusIcon} <span className='test'>{testStatus}</span></span>;
          return display;
        }
      },
      {
        Header: 'Service Levels',
        accessor: 'serviceLevels',
        Cell: cellInfo => {
          const className = getClassName(cellInfo.row.original);
          const tests = ServiceLevelHelper.findDistinctTests(cellInfo.cell.value);
          const anyInConflict = tests.some(test => ServiceLevelHelper.hasTestsInConflict(cellInfo.cell.value, test));
          const assetServiceLevels = cellInfo.row.original.serviceLevels.filter(serviceLevel => serviceLevel.performed);
          const serviceLevels = assetServiceLevels.length
            ? <div>{assetServiceLevels.map(serviceLevel => <ServiceLevelEntry key={serviceLevel.serviceLevelId} serviceLevel={serviceLevel} />)}</div>
            : null;
          return <span className={className + (anyInConflict ? ' service-level-in-conflict' : '')}>{serviceLevels}</span>
        }
      },
      {
        Header: 'Bill of Material',
        accessor: 'billofmaterial',
        isVisible: showSAPStatus,
        Cell: cellInfo => {
          const className = getClassName(cellInfo.row.original);
          const assetServiceLevels = cellInfo.row.original.serviceLevels.filter(serviceLevel => serviceLevel.performed);
          const billOfMaterial = controller.getBillOfMaterial(assetServiceLevels.length && assetServiceLevels[0]);
          var billOfMaterialCtl = (billOfMaterial === 'No Bill of Material')
            ? <i>{billOfMaterial}</i>
            : billOfMaterial;
          return <span className={className}>{billOfMaterialCtl}</span>
        }
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: (cellInfo) => {
          const className = getClassName(cellInfo.row.original);
          return <span className={className}>{cellInfo.cell.value}</span>
        }
      },
      {
        Header: 'Asset Status',
        accessor: 'assetStatus',
        Cell: cellInfo => {
          let assetStatus = cellInfo.cell.value;
          let statusColourClass = ' status' + assetStatus;
          if (assetStatus === 'Weld Repair' || assetStatus === 'Failed Inspection')
            statusColourClass = ' asset-status statusWeldRepair'
          if (assetStatus === 'OS/Machining Repair')
            statusColourClass = 'asset-status statusOSRepair'
          if (cellInfo.row.original.rejectReason) {
            if (assetStatus != 'Passed')
              assetStatus += ' – ' + cellInfo.row.original.rejectReason;
          }
          return <span className={statusColourClass}>{assetStatus}</span>
        }
      },
    ],
    [showSAPStatus]
  )

  return (
    <Grid id='results-table' responsive={false} autoResetSortBy={false} columns={columns} data={data} onRowClick={handleRowClick} autoResetSelectedRows={controller.state.jobChanged} allowMultiSelect={true} showPagination={false} selectionType={selectionType} getRowProps={getRowProps} noDataText='No jobs meet the search criteria defined above' />
  );
}

class AssetList extends React.Component {

  constructor(props, context) {
    super(props, context);
  }

  editRfid = () => {
    const asset = this.props.controller.getSelectedAsset();
    store.set(asset.assetId, asset);
    store.set('callingRoute', this.props.location.pathname);
    this.transitionTo('editrfid', { assetId: asset.assetId });
  }

  selectAll = () => {
    this.props.controller.selectAll();
  }

  selectScrapped = () => {
    this.props.controller.selectScrapped();
  }

  selectNone = () => {
    this.props.controller.selectNone();
  }

  getChartData = () => {
    this.props.controller.getChart();
  }

  getData = () => {
    return this.props.assets;
  }

  handleGenerateQRCodes = () => {
    const searchResults = this.getData();
    const filterSearchResults = searchResults.filter(result => result.selected == true);
    const qrSerialValues = filterSearchResults.length ? filterSearchResults : searchResults;
    const generatedQRCodes = <div>{qrSerialValues.map(row => <QRSticker qrValue={row} />)}</div>;
    let newWindow = window.open('', '_blank');
    const title = 'QR code'
    newWindow.document.write('<html><head><title>' + title + '</title><link rel="stylesheet" href="css/application.css"> </head><body>');
    ReactDOM.render(generatedQRCodes, newWindow.document.body);
    newWindow.document.write('</body></html>');
    newWindow.document.close();
    $(newWindow).on('load', function () {
      newWindow.print();
    });
  }

  render = () => {
    const controller = this.props.controller;
    const assets = this.getData();
    const results = assets.map(asset => <AssetRow key={asset.jobSOWId} controller={controller} asset={asset} />);
    const selectedCount = assets.filter(asset => asset.selected).length;
    const scrappedCount = assets.filter(asset => controller.getScrappedAssets(asset)).length;
    const osRepairCount = assets.filter(asset => controller.getOSRepairAssets(asset)).length;
    const weldRepairCount = assets.filter(asset => controller.getWeldRepairAssets(asset)).length;
    const failedCount = assets.filter(asset => controller.getFailedAssets(asset)).length;
    const shippedCount = assets.filter(asset => asset.hasShipped).length;
    const selectedLabel = selectedCount + ' / ' + assets.length + ' selected';
    const scrappedLabel = scrappedCount + ' scrapped';
    const osRepairLabel = osRepairCount + ' os/machining repair';
    const weldRepairLabel = weldRepairCount + ' weld repair';
    const failedLabel = failedCount + ' failed';
    const shippedLabel = shippedCount + ' shipped';
    const selectedAsset = controller.getSelectedAsset();
    const editIsDisabled = ((selectedCount !== 1) || (selectedAsset === null) || controller.isAssetScrapped(selectedAsset));
    const canGetChartData = (selectedAsset && (selectedAsset.hasChart|| selectedAsset.hasnonAPTCharts))
      ? 'true'
      : '';
    const job = controller.state.selectedJob;
    const isSAPFacility = SAPService.isSAPFacility();
    const colCount = isSAPFacility ? 13 : 12;
    let loadingMessage = controller.isLoading && job && (job.assetCount > 0) && <Table.Row><Table.Data colSpan={colCount} className='loading'>{'Loading ' + Utils.pluralize('asset', 's', job.assetCount)}</Table.Data></Table.Row>;
    let noAssetsMessage = '';
    if (!loadingMessage) {
      if (!job) {
        loadingMessage = <Table.Row><Table.Data colSpan={colCount} className='loading'>{'Please load a job for inspection'}</Table.Data></Table.Row>;
      }
      else if (!job.assetCount) {
        loadingMessage = <Table.Row><Table.Data colSpan={colCount} className='loading'>{`There are no assets on job {job.jobNumber}`}</Table.Data></Table.Row>;
      }
    }
    const selectedAssets = controller.getSelectedAssets();
    const key = 'assetId';
    const modifiedAssets = assets.filter(asset => asset.ModifiedAssetId != Utils.emptyGuid);
    const modifiedAssetsByAssetId = [...new Map(modifiedAssets.map(item => [item[key], item])).values()];
    let modCount = 0;
    
    if(selectedAssets?.length > 0){
        const selectedAssetsByAssetId = [...new Map(selectedAssets.map(item =>
          [item[key], item])).values()];
        selectedAssetsByAssetId.forEach(x => {
          var isAvailable = modifiedAssets.filter(y => y.assetId == x.assetId);
          if(isAvailable.length > 0){
            ++modCount;
          }
        });
    }
    const totalAssetModifiedCount = selectedAssets.length > 0 ? modCount : modifiedAssetsByAssetId.length;
    const modifiedAssetsCountLabel = totalAssetModifiedCount + ' modified service order';
    return (
      <div className='col-md-12' id='assets'>
        <div className='row'>
          <div className='col-md-12'>
            <h5>Assets</h5>
          </div>
        </div>
        <div className='' id='inspection-buttons' >
          <div className='col-md-12'>
            <div className='row'>
              {/* <Button icon='fa-rss' id='edit-asset' variant='info' size='sm' className='m-1'    disabled={editIsDisabled}   onClick={this.editRfid}>RFID ...</Button> */}
              <Button icon='fa-chart-line' variant='info' size='sm' className='m-1' disabled={!canGetChartData} onClick={this.getChartData}>Chart</Button>
              <Button icon='fa-square' variant='success' size='sm' className='m-1' onClick={this.selectAll}>Select All</Button>
              <Button icon='far fa-thumbs-down' variant='info' size='sm' className='m-1' onClick={this.selectScrapped}>Select Scrapped</Button>
              <Button icon='fa-qrcode' variant='primary' size='sm' className='m-1' disabled={!assets.length} onClick={this.handleGenerateQRCodes}>Print QR</Button>
              <Button icon='far fa-square' variant='danger' size='sm' className='m-1' onClick={this.selectNone}>Select None</Button>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='d-flex justify-content-between'>
              <div>
                <Badge variant='info' className='mr-1 mb-1 defects-count-badge'>{shippedLabel}</Badge>
                <Badge variant='danger' className='mr-1 mb-1'>{scrappedLabel}</Badge>
                <Badge variant='info' className='mr-1 mb-1 defects-count-badge'>{failedLabel}</Badge>
                <Badge variant='info' className='mr-1 mb-1 defects-count-badge'>{osRepairLabel}</Badge>
                <Badge variant='info' className='mr-1 mb-1 defects-count-badge'>{weldRepairLabel}</Badge>
                <Badge variant='info' className='ml-1 defects-count-badge'>{modifiedAssetsCountLabel}</Badge>
              </div>
              <div>
                <Badge variant='info' className='mr-1 mb-1'>{selectedLabel}</Badge>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <div id='asset-list'>
              <Results controller={controller} assets={assets} />
              {/* <Table>
                <Table.Header>
                  <Table.Row>
                    {isSAPFacility && <Table.Head sortKey='sapStatus,primarySerialNumber' className='status-column' getData={this.getData} sorter={this.sortData} />}
                    <Table.Head sortKey='groupNumber,hasShipped,primarySerialNumber' getData={this.getData} sorter={this.sortData}>Group</Table.Head>
                    <Table.Head sortKey='primarySerialNumber' getData={this.getData} sorter={this.sortData}>Primary Serial #</Table.Head>
                    <Table.Head sortKey='equipmentNumber' getData={this.getData} sorter={this.sortData}>Equipment #</Table.Head>
                    <Table.Head sortKey='familyCode,primarySerialNumber' getData={this.getData} sorter={this.sortData}>Family Code</Table.Head>
                    <Table.Head sortKey='size,primarySerialNumber' getData={this.getData} sorter={this.sortData}>Size</Table.Head>
                    <Table.Head sortKey='manufacturer,primarySerialNumber' getData={this.getData} sorter={this.sortData}>Mfg</Table.Head>
                    <Table.Head sortKey='cwp,primarySerialNumber' getData={this.getData} sorter={this.sortData}>Pressure</Table.Head>
                    <Table.Head sortKey='lastTestResult.status,primarySerialNumber' getData={this.getData} sorter={this.sortData}>Test Status</Table.Head>
                    <Table.Head>Service Levels</Table.Head>
                    {isSAPFacility && <Table.Head>Bill of Material</Table.Head>}
                    <Table.Head sortKey='description,primarySerialNumber' getData={this.getData} sorter={this.sortData}>Description</Table.Head>
                    <Table.Head sortKey='assetStatus,primarySerialNumber' getData={this.getData} sorter={this.sortData}>Asset Status</Table.Head>
                    {/* <Table.Head>Attachments</Table.Head> */}
              {/* </Table.Row>
                </Table.Header>
                <Table.Body>
                  {results}
                  {loadingMessage}
                  {noAssetsMessage}
                </Table.Body>
              </Table> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const FilteredAssets = (props) => {

  const binder = props.binder;
  const controller = props.controller;
  const assets = props.assets;

  return (
    <div className='col-md-12'>
      <div className='row'>
        <GroupFilter controller={controller} binder={binder} />
      </div>
      <div className='row'>
        <AssetList controller={controller} assets={assets} />
      </div>
    </div>
  );
}

const InspectionButton = (props) => {

  let history = useHistory();

  const handleClick = (test) => {
    if (!test.enabled) {
      return;
    }

    props.controller.setActiveTest(test.name);
    props.controller.canTecoUnTeco(test);
  }

  const handleAddReworkTime = (test) => {
    props.controller.addReworkTime(test);
  }

  const getStampBandData = () => {
    props.controller.getStampBandData();
  }

  const handleAddThicknessReading = () => {
    props.controller.addThicknessReadings();
  }

  const handleAddTorqueValues = () => {
    props.controller.addTorqueValues();
  }

  const handleAddDimensionValues = () => {
    props.controller.addDimensionValues();
  }

  const displayScrapSignature = (scrapSignature) => {
    props.controller.displayScrapSignature(scrapSignature);
  }

  const navigateToPressureTests = () => {
    const controller = props.controller;
    const queryData = {
      jobId: controller.state.selectedJob.id,
      customerId: controller.state.selectedJob.customerId,
      familyCodeId: controller.getCommonFamilyCodeId(),
      cwpId: controller.getCommonCWPId(),
      canEdit: controller.canEditPressureTests()
    };
    history.push({
      pathname: '/manualpressuretests/',
      state: { params: queryData }
    });

  }

  const handleNotesClick = (test) => {
    props.controller.addTestNotes(test);
  }

  const getActiveTestName = () => {
    const testName = props.controller.state.activeTestName;
    return testName;
  }

  const controller = props.controller;
  const test = props.test;
  const selectedAssets = controller.getSelectedAssets();
  const activeTestName = controller.state.activeTestName;
  let buttonClassName = 'test-btn btn-md';
  let smallTextClassName = '';
  let showGaugeIndicator = false;
  let isNA = false;
  let gaugesCount = 0;
  let passArray = [], failArray = [];
  if (test.name === 'Mag Particle Inspection (MPI)'
    || test.name === 'Thickness (UT)'
    || test.name === 'Gauge'
    || test.name === 'Pressure Test') {
    showGaugeIndicator = true;
    selectedAssets.forEach(asset => {
      const result = asset.testResults.filter(x => x.testName === test.name);
      if(result && result[0].NotApplicable){
        isNA = true;
      }
    });
  }
  if (selectedAssets.length > 0) {
    // const asset = selectedAssets[0];
    selectedAssets.forEach(asset => {
      let count = 0;
      if (!asset.gaugecountdto.length) {
        failArray.push(count)
      }
      for (let j = 0; j < asset.gaugecountdto.length; j++) {
        if (asset.gaugecountdto[j].TestName == test.name) {
          count += asset.gaugecountdto[j].GaugeCount;
        
        if (count > 0) {
          passArray.push(count);
        }
        else {
          failArray.push(count)
        }
      }
      gaugesCount = gaugesCount + count;
    }
      if(passArray.length === 0 && failArray.length === 0){
        failArray.push(count);
      }
    })
  }
  const loginDetail = store.get('InteServLoginInfo');
  const manualChartuploadEnabled = loginDetail ? loginDetail.enableManualChartUpload : false;
  const dmsEnabledFacility = loginDetail ? loginDetail.enableDMS : false;
  const isSAPFacility = SAPService.isSAPFacility();
  // const gaugeManagementIcon = showGaugeIndicator && dmsEnabledFacility && isSAPFacility
  const enableGaugeManagement = controller.state.inspectionJob.enableGuage;
  // const gaugeManagementIcon = enableGaugeManagement && showGaugeIndicator && dmsEnabledFacility && isSAPFacility
  // ? gaugesCount > 0
  //   ? <span className='passed'>
  //     <span className='gauge-indicator-icon'>
  //       <ActionIcon className='page-icon' icon='fa fa-tachometer-alt' size='sm' />
  //     </span>
  //   </span>
  //   : <span className='failed'>
  //     <span className='gauge-indicator-icon'>
  //       <ActionIcon className='page-icon' icon='fa fa-tachometer-alt' size='sm' />
  //     </span>
  //   </span>
  // : null;
  if (test.enabled && (test.name === activeTestName)) {
    buttonClassName += ' active test-btn-selected';
    smallTextClassName += 'test';
  }
  else {
    buttonClassName += ' btn-default';
  }

  if (test.enabled) {
    buttonClassName += ' ' + (test.className ? test.className : 'extrabutton');
  }
  else {
    buttonClassName += ' disabled-button';
    smallTextClassName += ' disabled-button';
  }

  var matchingTests = controller.getTestResults(selectedAssets, test.name);
  var testNotperformed = controller.isAssetsTestNotPerformed(matchingTests);
  var timeTrackingResults = controller.getTimeTrackingResults(selectedAssets, test.name);
  var testUpdatedDate = controller.getCommonTestDate(timeTrackingResults);
  var testPerformedBy = controller.getCommonTechnician(timeTrackingResults);
  var testOperationCode = controller.getOperationCode(matchingTests);
  var statusIcon = controller.getStatusAssetsIcon(matchingTests, selectedAssets);
  var statusText = controller.getStatusText(matchingTests);
  var lastTestResult = controller.getLastTestResultName(selectedAssets);
  var testDuration = controller.getTestDuration(selectedAssets, test.name);
  var hasScrappedAssetSignature = ((selectedAssets.length === 1) && (selectedAssets[0].scrapSignature));
  var canOverridePressureTests = ((test.name === 'Pressure Test') && controller.canOverridePressureTests());
  var anyAssetsScrapped = selectedAssets.some(asset => controller.isAssetScrapped(asset));
  var canAddReworkTime = !anyAssetsScrapped && controller.canAddReworkTime(statusIcon, testUpdatedDate);
  var isAddTorqueValueDisable = controller.state.isAddTorqueValueDisable;
  var isAddDimensionValuesDisable = controller.state.isAddDimensionValueDisable;
  var notPerformedTestsCount = controller.notPerformedTestsCount(matchingTests);
  var selectedAssetsOfSimilarsize = controller.isSimilarSize(selectedAssets);
  //var istecountecoDisable   = (controller.state.istecountecoDisable && controller.userHasTecoRights());   
  var btnReworkTime = props.showReworkTime && <GroupBadge variant='secondary' className='m-1' pill={false} disabled={!canAddReworkTime} onClick={() => handleAddReworkTime(test)}>Add Rework Time</GroupBadge>;
  const notesButton = selectedAssets.length === 1
    ? <GroupBadge variant='warning' className='m-1' pill={false} onClick={() => handleNotesClick(test)}>Notes</GroupBadge>
    : null;
  var isFracCustomer = controller.state.selectedJob ? controller.state.selectedJob.isFracCustomer : '';
  var addTorqueValues = <div style={{ marginLeft: '5px', marginTop: '5px', display: 'inline-block' }}>
    <GroupBadge variant='info' disabled={isAddTorqueValueDisable} pill={false} onClick={handleAddTorqueValues}>Add Torque Values</GroupBadge>
  </div>;
  // var tecoOrUnteco               = (selectedAssets.length !== 0) && selectedAssets[0].sapStatus === 'TECO';  
  //  var tecoUntecoButton          = tecoOrUnteco ?<div style={{marginLeft: '5px', marginTop: '5px', display: 'inline-block'}}><Button variant='info' size='sm' className='' disabled={!istecountecoDisable}  onClick={this.unteco}>Unteco</Button> </div>: 
  //  <div style={{marginLeft: '5px', marginTop: '5px', display: 'inline-block'}}><Button variant='info' size='sm' className='' disabled={!istecountecoDisable}  onClick={this.teco}>Teco</Button></div>
  var addDimensionValues = isFracCustomer
    ? <div style={{ marginLeft: '4px', marginTop: '5px', display: 'inline-block' }}>
      <GroupBadge variant='info' pill={false} disabled={isAddDimensionValuesDisable} onClick={handleAddDimensionValues}>Add Dimension Values</GroupBadge>
    </div>
    : null; 
  const nonAptFileuploadButton = selectedAssets.length === 1 && !selectedAssets[0].hasShipped && test.name === "Pressure Test" && manualChartuploadEnabled?
   <GroupBadge variant={selectedAssets[0].nonAPTCharts.length > 0 ? 'success' : 'danger'} pill={false} disabled={selectedAssets[0].hasShipped} onClick={() => { controller.displayandsavenonAPTChart() }}>Upload Chart (Non APT)</GroupBadge> : null;
  
  const gaugeManagementIcon = enableGaugeManagement && showGaugeIndicator && dmsEnabledFacility && !selectedAssetsOfSimilarsize
    ? <span className='disabled'>
      <span className='gauge-indicator-icon'>
        <ActionIcon className='page-icon' icon='fa fa-tachometer-alt' size='sm' />
      </span>
    </span>
    : (enableGaugeManagement && showGaugeIndicator && dmsEnabledFacility && testNotperformed) || isNA ?
      <span className='striked-gauge-indicator-icon'>
        <span className='fa-stack fa-sm '>
          <ActionIcon className='page-icon  fa-stack-1x' icon='fa fa-tachometer-alt' size='sm' />
          <ActionIcon className='page-icon fa-stack-1x' icon='fa-solid fa-slash' size='sm' />
        </span>
      </span>
      : enableGaugeManagement && showGaugeIndicator && dmsEnabledFacility
        ? passArray.length && !failArray.length && !notPerformedTestsCount > 0
          ? <span className='passed'>
            <span className='gauge-indicator-icon'>
              <ActionIcon className='page-icon' icon='fa fa-tachometer-alt' size='sm' />
            </span>
          </span>
          : !passArray.length && failArray.length > 0 && !notPerformedTestsCount > 0
            ? <span className='failed'>
              <span className='gauge-indicator-icon'>
                <ActionIcon className='page-icon' icon='fa fa-tachometer-alt' size='sm' />
              </span>
            </span>
            : <span className='success'>
              <span className='gauge-indicator-icon'>
                <ActionIcon className='page-icon' icon='fas fa-question' size='sm' />
              </span>
            </span>
        : null;

  // tried to get results using time tracking results, but failed to get any
  // fall back to using test results
  if (!testUpdatedDate) {
    testUpdatedDate = controller.getCommonTestDate(matchingTests);
  }

  // tried to get results using time tracking results, but failed to get any
  // fall back to using test results
  if (!testPerformedBy) {
    testPerformedBy = controller.getCommonTechnician(matchingTests);
  }

  if (lastTestResult === test.name) {
    smallTextClassName += ' most-recent';
  }

  const isMultiLineLabel = test.name === 'Thickness/Dimensional Measurements' ? 'multi-line-label' : '';


  var divClassName = test.enabled ? 'col-md-12 inspection-test-button' : 'invisible';

  return (
    <div className={divClassName} key={test.name}>
      <span className='test'>
        <Button variant='light' className={buttonClassName} onClick={() => { handleClick(test); }}>
          {gaugeManagementIcon}
          {/* {statusText} */} <span className={isMultiLineLabel + ' test-name font-weight-bold'}>{test.name}</span>{statusIcon}
          <span className={smallTextClassName + ' font-weight-bold test-performed-by mb-2'}>{testPerformedBy}</span>
          <span className={smallTextClassName + ' font-weight-bold test-date-updated'}>{testUpdatedDate}</span>
          <div className={smallTextClassName + ' font-weight-bold test-operation-code'}>{testOperationCode}</div>
          <div className={smallTextClassName + ' font-weight-bold test-duration'}>{testDuration}</div>
        </Button>
      </span>
      {btnReworkTime}

      {notesButton}   

      {nonAptFileuploadButton}
      {(test.name === 'Stamp/Band') && (selectedAssets.length === 1) &&
        <div><GroupBadge variant='info' pill={false} onClick={getStampBandData}>View Stamp/Band</GroupBadge></div>
      }
      {(test.name === 'Thickness (UT)') && (selectedAssets.length === 1) && dmsEnabledFacility &&
        <div><GroupBadge variant='info' pill={false} onClick={handleAddThicknessReading}>Record Reading</GroupBadge></div>
      }
      {canOverridePressureTests &&
        <div><GroupBadge variant='warning' pill={false} onClick={navigateToPressureTests}>Override Pressure Tests</GroupBadge></div>
      }
      {(test.name === 'Final Inspection') && hasScrappedAssetSignature &&
        <div><GroupBadge variant='danger' onClick={() => displayScrapSignature(selectedAssets[0].scrapSignature)}>View Scrap Details</GroupBadge></div>
      }
      {/* {(test.name.includes('Final Inspection')) && (selectedAssets.length>0) &&
            {tecoUntecoButton}
          } */}
      {(test.name.includes('Torque')) && (selectedAssets.length === 1) &&
        addTorqueValues
      }
      {(test.name.includes('Dimensional')) && (selectedAssets.length === 1) &&
        addDimensionValues
      }
    </div>
  );
}

const InspectionButtons = (props) => {

  let history = useHistory();

  const getActiveTest = (testName) => {
    let test = props.controller.state.inspectionTests.filter(t => t.name === testName)[0];
    if (!test) {
      test = props.controller.state.extraTests.filter(t => t.name === testName)[0];
      props.controller.canTecoUnTeco(test);
      props.controller.canAddTorqueValues(test);
      props.controller.canAddDimensionValues(test);
    }
    return test;
  }

  const getActiveTestName = () => {
    const testName = props.controller.state.activeTestName;
    return testName;
  }

  const passTest = () => {
    const test = getActiveTest(getActiveTestName());
    if (!test.enabled) {
      return;
    }
    props.controller.passTest(test);
  }

  const failTest = () => {
    const test = getActiveTest(getActiveTestName());
    if (!test.enabled) {
      return;
    }
    props.controller.failTest(test);
  }

  const temporaryFailTest = () => {
    const test = getActiveTest(getActiveTestName());
    if (!test.enabled) {
      return;
    }
    props.controller.temporaryFailTest(test);
  }

  const unteco = () => {
    // const test = getActiveTest(getActiveTestName());
    // if (!test.enabled) {
    //   return;
    // }
    props.controller.unTecoTest();
  }

  const teco = () => {
    // const test = getActiveTest(getActiveTestName());
    // if (!test.enabled) {
    //   return;
    // }
    props.controller.tecoTest();
  }

  const notPerformedTest = () => {
    const test = getActiveTest(getActiveTestName());
    if (!test.enabled) {
      return;
    }
    props.controller.notPerformedTest(test);
  }

  const loadDocuments = () => {
    props.controller.loadDocuments();
  }

  const handleAttachFiles = () => {
    const selectedAssets = props.controller.getSelectedAssets();
    if (selectedAssets.length == 0 || selectedAssets.length > 1) {
      return;
    }
    else {
      history.push('/inspectionattachments/' + selectedAssets[0].assetId + '/' + selectedAssets[0].primarySerialNumber + '/' + (selectedAssets[0].equipmentNumber != '' ? selectedAssets[0].equipmentNumber : '-'));
    }
  }
  const handleNotesImg = () => {
    const selectedAssets = props.controller.getSelectedAssets();
    if (selectedAssets.length == 0 || selectedAssets.length > 1) {
      return;
    } else {
      history.push('/notes/' + AppConstants.NoteTypes.Asset + '/' + selectedAssets[0].assetId);
    }
  }
  const handleModifiedTrans = () => {
    const job = controller.state.inspectionJob;
    const selectedAssets = props.controller.getSelectedAssets();
    const queryData = {
      selectedAssets: selectedAssets.map(x => x.primarySerialNumber),
      tabKey : '5',
      jobNo: job.jobNumber
    };
    history.push({
      pathname: '/transactions',
      state: { params: queryData }
    });
  }
  const weldRepair = () => {
    const test = getActiveTest(getActiveTestName());
    if (!test.enabled) {
      return;
    }
    props.controller.weldRepair(test);
  }

  const osRepair = () => {
    const test = getActiveTest(getActiveTestName());
    if (!test.enabled) {
      return;
    }
    props.controller.osRepair(test);
  }

  const FixPlant = () => {
    const selectedAsset = props.controller.getSelectedAssets();
    if (selectedAsset.length == 0) {
      return;
    }
    props.controller.fixPlant(selectedAsset);
  }

  const showGaugesPartNo = () => {
    const test = getActiveTest(getActiveTestName());
    if (!test.enabled) {
      return;
    }
    props.controller.showGaugesPartNo(test);
  }

  const recordInspectionTime = () => {
    const test = getActiveTest(getActiveTestName());
    if (!test.enabled) {
      return;
    }
    props.controller.recordInspectionTime(test);
  }

  const renderInspectionButtons = () => {
    const controller = props.controller;
    const selectedAssets = controller.getSelectedAssets();
    const inspectionTests = controller.state.inspectionTests.filter(test => test.enabled);
    const extraTests = controller.state.extraTests;
    let inspectionButtons = inspectionTests
      .filter(test => test.name !== 'Final Inspection')
      .concat(extraTests)
      .concat(inspectionTests.filter(test => test.name === 'Final Inspection'));

    inspectionButtons
      .forEach(inspectionButton => {
        inspectionButton.operationOrder = parseInt(controller.getOperationOrder(selectedAssets, inspectionButton.name), 10) || 0;
      });

    const pressureTestButtons = inspectionButtons.filter(button => ((button.name === 'Final Inspection') || (button.name === 'final inspection')));
    const pressureTestButtonOperationOrder = pressureTestButtons.length
      ? pressureTestButtons.reduce((acc, curr) => {
        return (curr.operationOrder > acc) ? curr.operationOrder : acc;
      }, 0)
      : 0;

    inspectionButtons = inspectionButtons
      .sort((a, b) => {
        return (a.operationOrder - b.operationOrder);
      })
      .map(test => <InspectionButton key={test.name} controller={controller} test={test} showReworkTime={pressureTestButtonOperationOrder >= test.operationOrder} />);

    return (
      <div className='row'>
        {inspectionButtons}
      </div>
    );
  }

  const editCertificate = () => {
    props.controller.editCertificate();
  }

  var controller = props.controller;
  var recordTimeClassName = 'action-button btn-test m-1';
  var passClassName = 'btn-test action-button m-1';
  var failClassName = 'btn-test action-button m-1';
  var compClassName = 'btn-test action-button  m-1';
  var notPerformedClassName = 'action-button btn-test m-1';
  var tecoUntecoClassName = 'action-button btn-test m-1';
  var weldRepairClassName = 'action-button btn-test m-1';
  var osRepairClassName = 'action-button btn-test m-1';
  var isSAPFacility = SAPService.isSAPFacility();
  var testName = getActiveTestName();
  var activeTest = getActiveTest(testName);
  var canDoInsp = !isSAPFacility;
  var loginInfo = store.get('InteServLoginInfo');
  var isLoggedInUserHasEmpId = (loginInfo && loginInfo.employeeId ? true : false);
  var isInspecting = controller.isInspecting();
  var jobIsWaiting = controller.isWaiting();
  var selectedAssets = controller.getSelectedAssets();
  var attachFilesDisabled = (selectedAssets.length == 0 || selectedAssets.length > 1);
  var notesDisabled = (selectedAssets.length == 0 || selectedAssets.length > 1);
  var anyAssetsScrapped = selectedAssets.some(asset => controller.isAssetScrapped(asset));
  var isSelectedAssetScrapped = selectedAssets.length ? controller.isAssetScrapped(selectedAssets[0]) : false;
  var anyAssetsShipped = selectedAssets.some(asset => asset.hasShipped);
  var anyAssetsWaitingLockForInspection = selectedAssets.some(asset => asset.isWaitingForLockForInspection);
  var anyAssetsThatCannotBeInspected = selectedAssets.some(asset => !asset.canInspect);
  var anyAssetsAlreadyPassed = activeTest
  var assetsWithoutServiceOrderNumbers = controller.assetsWithoutServiceOrderNumbers(selectedAssets);
  var operationOrderNotDefined = controller.isOperationOrderNotDefinedForAssets(selectedAssets);
  var isPreviousTestComplete = controller.isPreviousTestComplete(selectedAssets, activeTest);
  
  var selectedAssets = controller.getSelectedAssets();
  var matchingTests = controller.getTestResults(selectedAssets, testName);
  var IsTestPassed = controller.IsAssetTestAlreadyPassed(matchingTests, selectedAssets);
  var allmatchingTests = controller.getAllTestResults(selectedAssets);
  var IsTestFailed = controller.IsTestAlreadyFailed(allmatchingTests);
  var istecountecoDisable = (controller.state.istecountecoDisable && controller.userHasTecoRights() && !selectedAssets.some(asset => asset.sapStatus != 'TECO')) && !controller.state.untecoInProgress && controller.getReceivableAssets(selectedAssets);
  var isFinalPassed = controller.IsFinalAlreadyPassed(allmatchingTests);
  var tecoOrUnteco = isSAPFacility && !anyAssetsShipped && (selectedAssets.length !== 0) && (IsTestFailed || (isFinalPassed && selectedAssets.some(asset => asset.sapStatus != 'REL')));
  var passDisabled = (!isInspecting || assetsWithoutServiceOrderNumbers || !(activeTest && activeTest.enabled)
    || anyAssetsThatCannotBeInspected || anyAssetsScrapped || anyAssetsShipped || anyAssetsWaitingLockForInspection
    || operationOrderNotDefined || !isPreviousTestComplete || IsTestPassed || (isSAPFacility && !isLoggedInUserHasEmpId));



  // if(testName === "Pressure Test") {
  //   passDisabled = !controller.getPassedStatus(controller.getTestResults(selectedAssets, "Pressure Test"));
  // } 


  //var passDisabled                      = !controller.getPassedStatus(controller.getTestResults(selectedAssets, "Pressure Test"));
  /*(!isInspecting || assetsWithoutServiceOrderNumbers ||
  !(activeTest && activeTest.enabled) || anyAssetsThatCannotBeInspected ||
  anyAssetsScrapped || anyAssetsShipped || anyAssetsWaitingLockForInspection ||
  operationOrderNotDefined || !isPreviousTestComplete || canDoInsp || IsTestPassed);*/
  var recordTimeDisabled = passDisabled;
  var noFailReasonMessage = controller.reasonCannotFailSelection(testName);
  var noWeldFailReasonMessage = controller.reasonCannotWeldRepairSelection(testName);

  var failDisabled = (operationOrderNotDefined || assetsWithoutServiceOrderNumbers || anyAssetsThatCannotBeInspected
    || anyAssetsWaitingLockForInspection || !isPreviousTestComplete || !isInspecting || (selectedAssets.length === 0) || (noFailReasonMessage !== '')
    || !(activeTest && activeTest.enabled) || (isSAPFacility && !isLoggedInUserHasEmpId));

  // if(testName === "Pressure Test") {
  //   failDisabled                      = !controller.getFailedStatus(controller.getTestResults(selectedAssets, "Pressure Test"));
  // } 


  //var failDisabled                      = selectedAssets.length ? !passDisabled : true;/*(operationOrderNotDefined || assetsWithoutServiceOrderNumbers || anyAssetsThatCannotBeInspected || anyAssetsWaitingLockForInspection || !isPreviousTestComplete || !isInspecting || (selectedAssets.length === 0) || (noFailReasonMessage !== '') || !(activeTest && activeTest.enabled) || canDoInsp);*/
  var totalTestDuration = controller.getTestDuration(selectedAssets); // don't specify test name
  var totalRSDuration = controller.getRSDuration(selectedAssets);
  var weldRepairDisabled = (!isInspecting || assetsWithoutServiceOrderNumbers || !(activeTest && activeTest.enabled) || anyAssetsThatCannotBeInspected || anyAssetsScrapped || anyAssetsShipped || anyAssetsWaitingLockForInspection || operationOrderNotDefined || !isPreviousTestComplete || (selectedAssets.length === 0) || (noWeldFailReasonMessage !== ''));
  var osRepairDisabled = (operationOrderNotDefined || assetsWithoutServiceOrderNumbers || anyAssetsThatCannotBeInspected || anyAssetsWaitingLockForInspection || !isPreviousTestComplete || !isInspecting || (selectedAssets.length === 0) || (noFailReasonMessage !== '') || !(activeTest && activeTest.enabled));
  var compDisabled = (operationOrderNotDefined || assetsWithoutServiceOrderNumbers || anyAssetsWaitingLockForInspection || !isInspecting || (selectedAssets.length === 0) || selectedAssets.length > 1 || canDoInsp);
  var GaugesCount = 0;
  for (let i = 0; i < selectedAssets.length; i++) {
    const asset = selectedAssets[i];
    for (let j = 0; j < asset.gaugecountdto.length; j++) {
      if (asset.gaugecountdto[j].TestName == testName) {
        GaugesCount += asset.gaugecountdto[j].GaugeCount;
      }
    }
  }

  // var gaugeDisabled = ((selectedAssets.length === 0) || !isPreviousTestComplete || (selectedAssets.length > 1 && GaugesCount > 0));
  var isSelectedAssetsDiffSize = true;
  if (selectedAssets && selectedAssets.length > 1) {
    isSelectedAssetsDiffSize = selectedAssets.map(asset => asset.size).every((val, i, arr) => val === arr[0]);
  }
  var gaugeDisabled = !isPreviousTestComplete || !isSelectedAssetsDiffSize;

  var isFracCustomer = controller.state.selectedJob ? controller.state.selectedJob.isFracCustomer : '';

  var osRepairButton = isFracCustomer
    ? <Button icon='fa-wrench' variant='success' size='sm' className={osRepairClassName} disabled={osRepairDisabled} onClick={osRepair}>OS/Machining</Button>
    : null;
  var weldRepairButton = isFracCustomer
    ? <Button icon='fa-wrench' variant='danger' size='sm' className={weldRepairClassName} disabled={weldRepairDisabled} onClick={weldRepair}>Weld Repair</Button>
    : null;
  var componentButton = isSAPFacility
    ? <Button variant='info' size='sm' className={compClassName} disabled={compDisabled} onClick={FixPlant}>Parts List</Button>
    : null;
  var tecoUntecoButton = tecoOrUnteco ? <Button icon='fa-times         fa-lg' variant='primary' size='sm' className={tecoUntecoClassName} disabled={!istecountecoDisable} onClick={unteco}>Unteco</Button> : null;
  // ((isSAPFacility && isFinalPassed && selectedAssets.some(asset => asset.sapStatus=='REL'))?<Button icon='fa-times         fa-lg' variant='primary' size='sm' className={tecoUntecoClassName} disabled={!istecountecoDisable} onClick={teco}>Teco</Button>:null)
  var matchingTests = controller.getTestResults(selectedAssets, testName);
  var notPerformedTestsCount = controller.notPerformedTestsCount(matchingTests);
  var enableGuageDropdown = false;
  if (testName === 'Mag Particle Inspection (MPI)'
    || testName === 'Thickness (UT)'
    || testName === 'Gauge'
    || testName === 'Pressure Test') {
    enableGuageDropdown = true;
  }
  var loginDetail = store.get('InteServLoginInfo');
  var dmsEnabledFacility = loginDetail ? loginDetail.enableDMS : false;
  var enableGaugeManagement = controller.state.inspectionJob.enableGuage;
  var gaugesButton = enableGaugeManagement && dmsEnabledFacility
    ? <Button variant='info' size='sm' className={compClassName} disabled={gaugeDisabled || !enableGuageDropdown || (notPerformedTestsCount > 0) } onClick={showGaugesPartNo}>Gauges</Button>
    : null;
  var noEmpIdWarning = (isSAPFacility && !isLoggedInUserHasEmpId)
    ? <div className='disabled-inspection-message m-1'>Employee Id not found. Please contact Inteserv administrator.</div> : null;

  if (totalTestDuration) {
    totalTestDuration = 'Total test time for selected ' + Utils.pluralize('asset', 's', selectedAssets.length, false) + ': ' + totalTestDuration;
  }
  if (totalRSDuration) {
    totalRSDuration = 'Logistics Time: ' + totalRSDuration;
  }
  var classEditCertificate = 'simple-link m-1';
  if (!controller.canEditCertificate(selectedAssets)) {
    classEditCertificate += ' disabled m-1';
  }


  var notPerformedDisabled = passDisabled;
  if (!notPerformedDisabled) {
    // so far, it's enabled - verify we're not on the 'Final Inspection' task
    // can't set it to 'Not Performed' in UI
    notPerformedDisabled = (testName === 'Final Inspection');
  }
  var rsTime = isSAPFacility ? <Badge variant='info' className='m-1'>{totalRSDuration}</Badge> : null;
  var divDisabledMessage = null;
  if (!controller.state.selectedJob) {
    divDisabledMessage = <div className='fail-disabled-message'>No job selected</div>;
  }
  else if (controller.state.selectedJob && controller.state.selectedJob.isComplete) {
    divDisabledMessage = <div className='fail-waiting-message'><i className='fas fa-clock' />&nbsp;Job is complete</div>;
  }
  else if (jobIsWaiting) {
    divDisabledMessage = <div className='fail-waiting-message'><i className='fas fa-clock' />&nbsp;Job is waiting</div>;
  }
  else if (assetsWithoutServiceOrderNumbers) {
    var preamble = (selectedAssets.length === 1) ? 'The selected asset ' : 'At least one selected asset ';
    var suffix = (selectedAssets.length === 1) ? 'it ' : 'they ';
    divDisabledMessage = <div className='fail-disabled-message'>{preamble + 'does not have a Service Order Number - ' + suffix + ' must be locked for inspection'}</div>;
  }
  else if (!anyAssetsScrapped && !anyAssetsShipped && operationOrderNotDefined) {
    divDisabledMessage = <div className='fail-disabled-message'>Unable to record activity - the order of operations for the tests have not been defined for the service level</div>;
  }
  else if (anyAssetsWaitingLockForInspection) {
    preamble = (selectedAssets.length === 1) ? 'The selected asset ' : 'At least one selected asset ';
    divDisabledMessage = <div className='fail-disabled-message'>{preamble + 'is waiting for a lock for inspection request to complete'}</div>;
  }
  else if (anyAssetsThatCannotBeInspected) {
    preamble = (selectedAssets.length === 1) ? 'The selected asset ' : 'At least one selected asset ';
    divDisabledMessage = <div className='fail-disabled-message'>{preamble + 'is not in the correct state to allow inspection to begin'}</div>;
  }
  else if (noFailReasonMessage) {
    divDisabledMessage = <div className='fail-disabled-message'>{noFailReasonMessage}</div>;
  }
  else if (selectedAssets.length && IsTestPassed) {
    var msg = (selectedAssets.length === 1)
      ? 'Selected asset is already passed'
      : 'At least one selected asset is already passed';
    divDisabledMessage = <div className='fail-disabled-message'>{'Pass button is disabled because: ' + msg}</div>;
  }
  else if (selectedAssets.length && activeTest && !isPreviousTestComplete) {
    var msg = (selectedAssets.length === 1)
      ? 'the selected asset'
      : 'at least one of the selected assets';
    divDisabledMessage = <div className='fail-disabled-message'>
      <div>{'The previous test has not been completed yet for ' + msg}</div>
      <div>You must Pass or mark it as Not Performed before recording activity for this test</div>
    </div>;
  }

  return (
    <div className='row' id='inspection-test-buttons' >
      <div className='col-md-12'>
        <Button icon='fa-clock' variant='secondary' size='sm' className={recordTimeClassName} disabled={recordTimeDisabled} onClick={recordInspectionTime}>Record Time</Button>
        <Button icon='far fa-thumbs-up' variant='success' size='sm' className={passClassName} disabled={passDisabled} onClick={passTest}>Pass</Button>
        <Button icon='far fa-thumbs-down' variant='danger' size='sm' className={failClassName} disabled={failDisabled} onClick={failTest}>Fail</Button>
        <Button icon='fa-times' variant='primary' size='sm' className={notPerformedClassName} disabled={notPerformedDisabled} onClick={notPerformedTest}>Not Performed</Button>
        {osRepairButton}
        {weldRepairButton}
        {/* {controller.state.inspectionJob.failInspection?<Button icon='fa-thumbs-o-down   fa-lg'  className={failClassName}         disabled={passDisabled}           onClick={temporaryFailTest}>Temporary Fail ...</Button>:null} */}
        {componentButton}
        {tecoUntecoButton}
        <Button icon='fa-plus-circle' disabled={attachFilesDisabled} variant='success' size='sm' className='action-button btn-test m-1' onClick={handleAttachFiles}>Attach Files</Button>
        {gaugesButton}        
        <Button id='btn-customerUpload' disabled={attachFilesDisabled} to={'/customers/documents/' + controller.state?.selectedJob?.customerId + '/' + 'customerUpload' + '/' + 'fromInspectionPage'} variant='primary' size='sm' className='m-1 action-button' >Customer Standard</Button>
        <Button id='btn-tfmcUpload' disabled={attachFilesDisabled} to={'/customers/documents/' + controller.state?.selectedJob?.customerId + '/' + 'tfmcUpload' + '/' + 'fromInspectionPage'} variant='primary' size='sm' className='m-1 action-button' >TFMC Standard</Button>
        <Button icon='fa-comments' disabled={notesDisabled} variant='info' size='sm' className='btn-test action-button m-1' onClick={() => { handleNotesImg(); }}>Notes</Button>
        <Button style={{ 'padding-right': '4px','padding-left': '3px'}} variant='info' size='sm' className='m-1' onClick={() => handleModifiedTrans()} >Modified Transactions</Button>
        {noEmpIdWarning}
        {rsTime}
        <Badge variant='info' className='m-1'>{totalTestDuration}</Badge>
        <Form.Link className={classEditCertificate} disabled onClick={editCertificate} value='3rd Party Certificate'></Form.Link  >
      </div>
      <div className='col-md-12'>
        {divDisabledMessage}
      </div>
      <div className='col-md-12'>{renderInspectionButtons()}</div>
    </div>
  );
}

const JobSummaryBox = (props) => {

  const toggleJobSummary = () => {
    props.controller.toggleJobSummary();
  }

  const handleEdit3rdPartyJobNumber = () => {
    props.controller.edit3rdPartyJobNumber();
  }

  const controller = props.controller;
  const job = controller.state.selectedJob;
  const jobId = job && job.id || '';
  const summaryLabel = controller.state.showSummary ? 'Hide Job Summary' : 'Show Job Summary';
  const summaryIcon = controller.state.showSummary ? 'fa-eye-slash' : 'fa-eye';
  const canShowNotes = job;
  const noteCountBadge = job && job.noteCount ? <Badge variant='warning' className='mt-2 ml-1'>{job.noteCount}</Badge> : null;
  const canEdit3rdPartyJobNumber = controller.canEdit3rdPartyJobNumber();

  return (
    <div className='row'>
      <div className='col-md-12 mb-2 mt-2'>
        <Button icon={summaryIcon} variant='primary' size='sm' className='btn-test m-1' onClick={toggleJobSummary}>{summaryLabel}</Button>
        <Button icon='fa-pencil-alt' variant='secondary' size='sm' disabled={!canEdit3rdPartyJobNumber} className='btn-test m-1' onClick={handleEdit3rdPartyJobNumber}>3rd Party Job #</Button>
        <Button icon='fa-comments' variant='info' size='sm' disabled={!canShowNotes} className='btn-test m-1' to={'/notes/' + AppConstants.NoteTypes.Job + '/' + jobId}>Job Notes</Button>
        {noteCountBadge}
      </div>
      <div className='col-md-12'><JobSummary job={job} hide={!controller.state.showSummary} /></div>
    </div>
  );
}

const InspectionTab = (props) => {

  const binder = props.binder;
  const controller = props.controller;
  const assets = controller.getVisibleAssets();

  return (
    <div className='col'>
      <div className='row'>
        <div className='col-md-7'>
          <div className='row'>
            <FilteredAssets controller={controller} binder={binder} assets={assets} />
          </div>
        </div>
        <div className='col-md-5'>
          <div className='row'>
            <div className='col-md-12'><InspectionButtons controller={controller} /></div>
            <div className='col-md-12'><JobSummaryBox controller={controller} /></div>
          </div>
        </div>
      </div>
    </div>
  );
}

const InspectionPageTabs = (props) => {
  const controller = props.controller;
  const binder = props.binder;
  const inspectionTabTitle = 'Inspection';
  const utScopeTabTitle = 'UT Scope';
  const loginDetail = store.get('InteServLoginInfo');
  const dmsEnabledFacility = loginDetail ? loginDetail.enableDMS : false;
  useEffect(() => {
    return () => {
      props.controller.selectNone();
    };
  }, []);
  return (
    <Tabs defaultActiveKey={1} onSelect={(k) => controller.tabShow(k)}>
      <Tab eventKey={1} title={inspectionTabTitle}>
        <Tab.Pane>
          <InspectionTab controller={controller} binder={binder} />
        </Tab.Pane>
      </Tab>
      {dmsEnabledFacility && <Tab eventKey={2} title={utScopeTabTitle}><Tab.Pane>
        <UTScopePage controller={controller} binder={binder} />
      </Tab.Pane></Tab>}
    </Tabs>
  );
}

const InspectionPageHeader = (props) => <PageHeader title={props.pageTitle} icon='fa fa-cogs' />

class InspectionPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    Object.assign(this, Controller);
    Object.assign(this, Authentication);
  }

  static defaultProps = {
    controller: new InspectionController()
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandTechnician();
    const jobId = this.props.match.params.jobId;
    if (jobId) {
      this.props.controller.loadJob(jobId);
    }
  }

  render = () => {
    const binder = new Binder(this);
    const controller = this.props.controller;
    const jobId = controller.state.selectedJob ? controller.state.selectedJob.id : '';

    return (
      <Page pageTitle='InteServ · Inspection' id='inspection-page'>
        <InspectionPageHeader pageTitle={'Inspection'} />
        <Page.Content>
          <div className='row'>
            <JobFilter className='col-md-4' controller={controller} binder={binder} />
          </div>
          <div className='row'>
            <div className='col-12'>
              <InspectionPageTabs controller={controller} binder={binder} />
            </div>
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default InspectionPage;
