import 'signalr';
import $ from 'jquery';
import Ajax from './Ajax'
import Messages from './Messages';
import store from 'store';

let token = store.get('oktaaccesstoken');
const signalR = $.signalR;
const location = window.location; 
const appServer = {
    ssl: location.protocol,
    domain: location.hostname,
    port: (location.port === '') ? '' : ':' + parseInt(location.port, 10)
};


function makeProxyCallback(hub, callback) {
    return function () {
        // Call the client hub method
        callback.apply(hub, $.makeArray(arguments));
    };
}

function registerHubProxies(instance, shouldSubscribe) {
    let key;
    let hub;
    let memberKey;
    let memberValue;
    let subscriptionMethod;

    for (key in instance) {
        if (instance.hasOwnProperty(key)) {
            hub = instance[key];

            if (!(hub.hubName)) {
                // Not a client hub
                continue;
            }

            if (shouldSubscribe) {
                // We want to subscribe to the hub eventss
                subscriptionMethod = hub.on;
            } else {
                // We want to unsubscribe from the hub events
                subscriptionMethod = hub.off;
            }

            // Loop through all members on the hub and find client hub functions to subscribe/unsubscribe
            for (memberKey in hub.client) {
                if (hub.client.hasOwnProperty(memberKey)) {
                    memberValue = hub.client[memberKey];

                    if (!$.isFunction(memberValue)) {
                        // Not a client hub function
                        continue;
                    }
                    subscriptionMethod.call(hub, memberKey, makeProxyCallback(hub, memberValue));
                }
            }
        }
    }
}

$.hubConnection.prototype.createHubProxies = function () {
    let proxies = {};
    this.starting(function () {
        // Register the hub proxies as subscribed
        // (instance, shouldSubscribe)
        registerHubProxies(proxies, true);

        this._registerSubscribedHubs();
    }).disconnected(function () {
        // Unsubscribe all hub proxies when we "disconnect".  This is to ensure that we do not re-add functional call backs.
        // (instance, shouldSubscribe)
        registerHubProxies(proxies, false);
    });

    proxies.messageHub = this.createHubProxy('messageHub');
    proxies.messageHub.client = {};
    proxies.messageHub.server = {
        send: function (channel, action, data) {
            return proxies.messageHub.invoke.apply(proxies.messageHub, $.merge(['Send'], $.makeArray(arguments)));
        }
    };
    return proxies;
};

signalR.hub = $.hubConnection(`${appServer.ssl}//${appServer.domain}${appServer.port}`, { useDefaultPath: false });
$.extend(signalR, signalR.hub.createHubProxies());

$.connection.hub.url = `${Ajax.server.ssl}://${Ajax.server.domain}${Ajax.server.port}/${Ajax.server.vdir}signalr`;
$.signalR.ajaxDefaults.headers = { Authorization: "Bearer " + token };

const messageHub = $.connection.messageHub;
messageHub.client.sendMessage = function(channel, action, data) {
  Messages.channel(channel).action(action).post(data);
};
if(token){
 $.connection.hub.start().done(function() {
   console.log('signalr started at', $.connection.hub.url);
}); }
$.connection.hub.error(function (error) {
    console.log('SignalR error: ' + error)
});
$.connection.hub.disconnected(function() {
  setTimeout(function() {
    if(token){
    $.signalR.ajaxDefaults.headers = { Authorization: "Bearer " + token };
    $.connection.hub.start().done(function() {
       //console.log('signalr reconnected at', $.connection.hub.url);
    });
}
  }, 5000); // Restart connection after 5 seconds.
});