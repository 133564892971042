import $ from 'jquery';
import React from 'react';
import store from 'store';
import Dialog from '../../components/Dialog';
import AppConstants from '../utility/AppConstants';
import Roles from '../utility/Roles';
import Utils from '../utility/Utils';
import ajax from '../../services/Ajax';
import messages from '../../services/Messages';
import notification from '../../services/Notification';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import ChangePasswordDialog from '../dialogs/ChangePasswordDialog';
import ChangePasswordController from '../controllers/ChangePasswordController';
import ClockInDialog from '../dialogs/ClockInDialog';
import ClockInController from '../controllers/ClockInController';
import BreakInDialog from '../dialogs/BreakInDialog';
import BreakInController from '../controllers/BreakInController';
import { useHistory } from 'react-router-dom';

const loginMessages = messages.channel('login');


class LoginService {    
    constructor() {

        messages.channel('ajax').action('unauthorized').subscribe(flag => {
            //alert('unauthorized');
            if (flag) {
                this.logout();
            }
        });

        messages.channel('ajax').action('unauthorizedV2').subscribe(flag => {
            //alert('unauthorizedV2');
            if (flag) {
                this.recordLogoutv2();
            }
        });


        messages.channel('edit-user').action('password-reset').subscribe(user => {
            if (user.userId !== this.loginInfo.userId) {
                return;
            }
            ajax.get('security/loginInfo').then((updatedUser) => {
                if (updatedUser) {
                    this.loginInfo = { ...this.loginInfo, ...{ passwordChangeDate: updatedUser.passwordChangeDate, daysUntilPasswordExpires: updatedUser.daysUntilPasswordExpires, attemptsLeft: updatedUser.attemptsLeft } };
                }
            })
                .catch(err => {
                    notification.action('error').post({ msg: err.message, title: 'Error' });
                });
        });

        messages.channel('clockInInfo').action('update').subscribe((clockInInfo) => {
            this.updateClockinInfo(clockInInfo);
        });

        messages.channel('productiveTime').action('update').subscribe((productiveTime) => {
            this.updateProductveTime(productiveTime);
        });

        messages.channel('clockInProcess').action('start').subscribe(data => {
            if (data.userId) {
                if (this.loginInfo.userId == data.userId) {
                    this.initiateClockInProcess();
                }
            }
        });

        messages.channel('clockInProcess_Cli').action('start').subscribe(() => {
            this.initiateClockInProcess();
        });

    }

    isLoggedInUser = (userId) => {
        if (!this.loginInfo || !userId) {
            return false;
        }
        return (userId.toLowerCase() === this.loginInfo.userId.toLowerCase());
    }
    
    getCustomer = () => {
        if (!this.loginInfo && !this.loginInfo?.customerName) {
            return false;
        }
        return this.loginInfo.customerName.toLowerCase();
    }

    isCookieExpired = () => {
        return (document.cookie.indexOf('InteServ=') === -1);
    }

    updateLoginInfoFormLocalStore = () => {
        this.loginInfo = store.get('InteServLoginInfo');
    }

    updateLoginInfoToLocalStore = () => {
        store.set('InteServLoginInfo', this.loginInfo);
    }

    updateClockinInfo = (clockinInfo) => {
        this.updateLoginInfoFormLocalStore();
        if (this.loginInfo) {
            this.loginInfo.isClockedIn = clockinInfo.isClockedIn;
            this.loginInfo.isClockedOut = clockinInfo.isClockedOut;
            this.loginInfo.isBreakOut = clockinInfo.isBreakOut;
            this.loginInfo.clockedInTime = clockinInfo.isClockedIn ? DateTimeFormatter.formatDateTime(clockinInfo.clockedInTime) : '';
            this.loginInfo.clockedOutTime = clockinInfo.isClockedOut ? DateTimeFormatter.formatDateTime(clockinInfo.clockedOutTime) : '';
            this.loginInfo.breakOutTime = clockinInfo.isBreakOut ? DateTimeFormatter.formatDateTime(clockinInfo.breakOutTime) : '';
            this.loginInfo.totalBreakTime = clockinInfo.totalBreakTime;
            this.loginInfo.totalProductiveTime = clockinInfo.totalProductiveTime;
            this.loginInfo.lastClockOutTime = (clockinInfo.lastClockOutTime ? clockinInfo.lastClockOutTime : this.loginInfo.lastClockOutTime);
            this.updateLoginInfoToLocalStore();
            messages.channel('loginService').action('update').post();
        }
    }

    updateProductveTime = (productiveTime) => {
        this.updateLoginInfoFormLocalStore();
        this.loginInfo.totalProductiveTime = productiveTime;
        this.updateLoginInfoToLocalStore();
        messages.channel('loginService').action('update').post();
    }

    isUserClockedin = (userId) => {
        ajax.post('manHours/isClockedIn', { userId: userId, today: DateTimeFormatter.formatLocalTime(DateTimeFormatter.now()) }).then((result) => {
            const isClockedIn = (result && result.ClockInTime) ? true : false;
            const isClockedOut = (result && result.ClockOutTime) ? true : false;
            const isBreakOut = (result && result.BreakOutTime) ? true : false;
            const ClockInTime = (result && result.ClockInTime) ? result.ClockInTime : '';
            const ClockOutTime = (result && result.ClockOutTime) ? result.ClockOutTime : '';
            const BreakOutTime = (result && result.BreakOutTime) ? result.BreakOutTime : '';
            const totalBreakTime = (result && result.totalBreakTime) ? result.totalBreakTime : 0;
            const lastClockOutTime = (result && result.lastClockOutTime) ? result.lastClockOutTime : '';
            const totalProductiveTime = (result && result.totalProductiveTime) ? result.totalProductiveTime : 0;
            const data = {
                isClockedIn: isClockedIn,
                isClockedOut: isClockedOut,
                clockedInTime: ClockInTime || '',
                clockedOutTime: ClockOutTime || '',
                isBreakOut: isBreakOut,
                breakOutTime: BreakOutTime || '',
                totalBreakTime: totalBreakTime,
                totalProductiveTime: totalProductiveTime,
                lastClockOutTime: lastClockOutTime
            };
            this.updateClockinInfo(data);
            this.updateLoginInfoFormLocalStore();
            if (this.loginInfo) {
                if (isClockedIn && isBreakOut) {
                    Dialog.showDialog(<BreakInDialog controller={new BreakInController()} />);
                }
                else if (!isClockedIn && !isClockedOut) {
                    Dialog.showDialog(<ClockInDialog controller={new ClockInController()} />);
                }
            }
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });

    }

    BreakIn = () => {
        ajax.post('manHours/breakInNow', { userId: this.loginInfo.userId, today: DateTimeFormatter.formatLocalTime(DateTimeFormatter.now()) }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    BreakOut = () => {
        const breakOutTime = DateTimeFormatter.formatLocalTime(DateTimeFormatter.now());
        ajax.post('manHours/breakOutNow', { userId: this.loginInfo.userId, today: breakOutTime }).then(() => {
            this.logout();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    initiateClockInProcess = () => {
        const currentFacility = store.get('currentFacility');
        if (currentFacility && currentFacility.useSAP && currentFacility.useTTS && !this.loginInfo.isCustomer) {
            this.isUserClockedin(this.loginInfo.userId);
        }
    }

    getUserNotifications = () => {
        messages.channel('userNotification').action('send').post({ userId: this.loginInfo.userId });
    }

    getActiveUsers() {
        ajax.get('lookup/getAllUsers').then((users) => {
            this.users = users.filter(item => item.display !== ' ');
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    autoLogin = () => {
        this.updateLoginInfoFormLocalStore();
        if (!this.loginInfo) {
            loginMessages.action('loggedin').post(null);
            return;
        }

        ajax.get('security/ping').then(() => {
            loginMessages.action('loggedin').post(this.loginInfo);
            const token=store.get('oktaaccesstoken');
            $.signalR.ajaxDefaults.headers = { Authorization: "Bearer " + token };
            if(token){
            $.connection.hub.start().done(function () {
                //console.log('signalr started at autologin', $.connection.hub.url);
            });}
            
$.connection.hub.logging = true;
            this.initiateClockInProcess();
            this.getUserNotifications();
            this.getActiveUsers();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    // login = (username, password) => {
    //     if (!username || !password) {
    //         loginMessages.action('loggedin').post(null);
    //     }
    //     else {
    //         var parameters = { username: username, password: password };
    //         ajax.post('security/login', parameters).then((loginInfo) => {
    //             if (this.hasPasswordExpired(loginInfo.daysUntilPasswordExpires)) {
    //                 Dialog.showDialog(<ChangePasswordDialog controller={new ChangePasswordController(loginInfo, 'Your password has expired -- you must change it now', true)} />);
    //             }
    //             else {
    //                 this.loginInfo = loginInfo;
    //                 store.clearAll();
    //                 this.updateLoginInfoToLocalStore();
    //                 this.initializeAutoLogoutTimer();
    //                 ajax.get('lookup/getFacility', { facilityId: loginInfo.facilityId }).then((results) => {
    //                     store.set('currentFacility', results);
    //                     loginMessages.action('loggedin').post(this.loginInfo);
    //                     if (this.isPasswordExpiring(loginInfo.daysUntilPasswordExpires)) {
    //                         this.showPasswordExpirationWarning(loginInfo.daysUntilPasswordExpires);
    //                     }
    //                     this.initiateClockInProcess();
    //                     this.getUserNotifications();
    //                     this.getActiveUsers();
    //                 }).catch(err => {
    //                     notification.action('error').post({ msg: err.message, title: 'Error' });
    //                 });
    //                 $.connection.hub.start().done(function () {
    //                     //console.log('signalr started at login', $.connection.hub.url);
    //                 });
    //             }
    //         })
    //             .catch(err => {
    //                 notification.action('error').post({ msg: err.message, title: 'Error' });
    //             });
    //     }
    // }

    login = (email,username, password) => {
        if (!email) {
            loginMessages.action('loggedin').post(null);
        }
        else {
            var parameters = { email: email,username: username, password: password };
            ajax.post('security/login', parameters).then((loginInfo) => {
                        // if (this.hasPasswordExpired(loginInfo.daysUntilPasswordExpires)) {
                        //     Dialog.showDialog(<ChangePasswordDialog controller={new ChangePasswordController(loginInfo, 'Your password has expired -- you must change it now', true)} />);
                        // }
                        // else {
                            this.loginInfo = loginInfo;
                            let token = store.get('oktaaccesstoken');
                            store.clearAll();
                            this.updateLoginInfoToLocalStore();
                            //this.initializeAutoLogoutTimer();
                            store.set('oktaaccesstoken', token);
                            ajax.get('lookup/getFacility', { facilityId: loginInfo.facilityId }).then((results) => {
                                store.set('currentFacility', results);
                                loginMessages.action('loggedin').post(this.loginInfo);
                                if (this.isPasswordExpiring(loginInfo.daysUntilPasswordExpires)) {
                                    this.showPasswordExpirationWarning(loginInfo.daysUntilPasswordExpires);
                                }
                                this.initiateClockInProcess();
                                this.getUserNotifications();
                                this.getActiveUsers();
                            }).catch(err => {
                                notification.action('error').post({ msg: err.message, title: 'Error' });
                            });
                            $.signalR.ajaxDefaults.headers = { Authorization: "Bearer " + token };
                            if(token){
                            $.connection.hub.start().done(function () {
                                //console.log('signalr started at login', $.connection.hub.url);
                            });}
                            // }
                })
                .catch(err => {
                    notification.action('error').post({ msg: err.message, title: 'Error' });
                });
        }
    }

    passwordExpirationMessage = (daysUntilPasswordExpires) => {
        if (daysUntilPasswordExpires === 0) {
            return 'Password expires today';
        }
        return Utils.pluralize('day', 's', daysUntilPasswordExpires) + ' until password expires';
    }

    showPasswordExpirationWarning = (daysUntilPasswordExpires) => {
        var message = this.passwordExpirationMessage(daysUntilPasswordExpires);
        notification.action('error').post({ msg: message, title: 'Password Expiration Warning' });
    }

    isPasswordExpiring = (daysUntilPasswordExpires) => {
        return (!this.hasPasswordExpired(daysUntilPasswordExpires) && (daysUntilPasswordExpires < AppConstants.PasswordExpirationWarningDays));
    }

    hasPasswordExpired = (daysUntilPasswordExpires) => {
        return (daysUntilPasswordExpires < 0);
    }

    initializeAutoLogoutTimer = () => {
        this.expirationTimer = setInterval(() => {
            if (this.isCookieExpired()) {
                this.logout();
                notification.action('error').post(ajax.sessionTimeoutMessage);
            }
        }, 5000);
    }

    isInRole = (rls) => {
        var roles = rls;
        if (!this.loginInfo || !this.loginInfo.role || !roles) {
            return false;
        }

        if (!Utils.isArray(roles)) {
            roles = [roles];
        }
        return roles.some(r => r && this.loginInfo.role.toLowerCase() === r.toLowerCase());
    }

    recordLogout = () => {
        if (this.loginInfo) {
            var parameters = {
                trackingId: this.loginInfo.trackingId
            };
            ajax.post('security/logout', parameters).then(()=>{               
                if (this.loginInfo) {
                    //clearInterval(this.expirationTimer);
                    loginMessages.action('loggedin').post(null);
                    delete this.loginInfo;   
                    store.clearAll();                             
                    
                }                 
                // const history = useHistory();
               // history.push('/login');
                window.location = '/';
            });
        }
    }

    recordLogoutv2 = () => {
        
        loginMessages.action('loggedin').post(null);
        delete this.loginInfo;   
        store.clearAll();                             

        // const history = useHistory();
        // history.push('/login');
        window.location = '/';
    }

    logout = () => {
        this.recordLogout();  
    }

    getDefaultRoute = (loginInfo) => {
        let path;
        if (loginInfo && loginInfo.isCustomer) {
            path = '/fieldasset';
        }
        else {
            switch (loginInfo && loginInfo.role) {
                case Roles.Technician:
                    path = '/receiving';
                    break;
                case Roles.Coordinator:
                    path = '/jobprogress';
                    break;
                case Roles.SAPRep:
                    path = '/sapjobprogress';
                    break;
                case Roles.DataClerk:
                    path = '/dropoff';
                    break;
                case Roles.Administrator:
                    path = '/jobprogress';
                    break;
                default:
                    path = null;
            }
        }
        return path;
    }

    updateFacility = (facility) => {
        this.loginInfo.facilityId = facility.id;
        this.loginInfo.facilityName = facility.name;
        this.updateLoginInfoToLocalStore();
        ajax.get('lookup/getFacility', { facilityId: facility.id }).then((results) => {
            store.set('currentFacility', results);
            messages.channel('loginService').action('update').post();
            this.initiateClockInProcess();
        }).catch(err => {
            notification.action('error').post({ msg: err.message, title: 'Error' });
        });
    }

    updateEmployeeId = (employeeId, userId) => {
        if (this.loginInfo.userId == userId) {
            this.loginInfo.employeeId = employeeId;
            this.updateLoginInfoToLocalStore();
        }
    }

    get isAdministrator() { return this.isInRole([Roles.Administrator]); }
    get isSuperUser() { return this.isInRole([Roles.Administrator, Roles.SuperUser]); }
    get isCoordinator() { return this.isInRole([Roles.Administrator, Roles.SuperUser, Roles.Coordinator]); }
    get isSAPRep() { return this.isInRole([Roles.Administrator, Roles.SuperUser, Roles.SAPRep]); }
    get isDataClerk() { return this.isInRole([Roles.Administrator, Roles.SuperUser, Roles.Coordinator, Roles.DataClerk]); }
    get isTechnician() { return this.isInRole([Roles.Administrator, Roles.SuperUser, Roles.Coordinator, Roles.DataClerk, Roles.Technician]); }
    get isTechnicianWithoutDataClerk() { return this.isInRole([Roles.Administrator, Roles.SuperUser, Roles.Coordinator, Roles.Technician]); }
    get isCustomer() { return this.loginInfo && this.loginInfo.isCustomer; }
    get isOnlySAPRep() { return this.isInRole([Roles.SAPRep]); }
    get isOnlyTechnician() { return this.isInRole([Roles.Technician]); }
    get isLibertycustomer() { return this.getCustomer() === 'liberty oilfield services' }
}

export default new LoginService();