import axios from 'axios';
import store from 'store';
import messages from '../services/Messages';
import CryptoJS from 'crypto-js';
import LoginService from '../app/services/LoginService';

axios.interceptors.request.use((config) => {
  messages.channel('loader').action('update').post(true);
  return config;
}, (error) => {
  messages.channel('loader').action('update').post(false);
  return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
  messages.channel('loader').action('update').post(false);
  return response ? response.data : null;
}, (error) => {
  const networkError = 'Network Error';
  const serverUnavailable = 'Server is Unavailable.';
  if (error.response) {
    switch (error.response.status) {
      case 401:
        error.message = 'Your session has timed out due to inactivity.'
        messages.channel('ajax').action('unauthorizedV2').post(true);
        //LoginService.recordLogoutv2();
        break;
      case 400:
        if(error.response.data && error.response.data.error_description)
        {
          error.message = error.response.data.error_description;
        }
        break;
        case 500:
          console.log('error.response.data');
          console.log(error.response.data);
          if(error.response.data && error.response.data.InnerException)
          {
            if(error.response.data.InnerException.ExceptionMessage.includes("Unauthorized access"))
            {
              messages.channel('ajax').action('unauthorized').post(true);
            }
          }
          if(error.response.data && error.response.data.ExceptionMessage)
          {
            error.message = error.response.data.ExceptionMessage;
          }
          break;
      default:
        error.message = error.message === networkError ? serverUnavailable : error.message;
    }
  }
  else if (error.request) {
    error.message = error.message === networkError ? serverUnavailable : error.message;
  } 
  messages.channel('loader').action('update').post(false);
  return Promise.reject(error);
});

class Ajax {
    constructor() {
      this.server = {
        ssl: process.env.REACT_APP_API_SSL,
        domain: process.env.REACT_APP_API_HOST_NAME,
        port: process.env.REACT_APP_API_PORT ? ':' + process.env.REACT_APP_API_PORT : '',
        vdir: process.env.REACT_APP_API_VDIR ? process.env.REACT_APP_API_VDIR + '/' : '',
        cors: process.env.REACT_APP_API_CORS === "true" ? true : false,
        api: process.env.REACT_APP_API,
        timeout: parseInt(process.env.REACT_APP_API_TIMEOUT ? process.env.REACT_APP_API_TIMEOUT : 0)
      };
      this.url = `${this.server.ssl}://${this.server.domain}${this.server.port}/${this.server.vdir}${this.server.api}/`;
      this.spinnerHandle = null;
      this.clientId = process.env.REACT_APP_CLIENT_ID;
      this.clientSecret = process.env.REACT_APP_CLIENT_SECRET;
      this.secretKey = process.env.REACT_APP_SECRET_KEY;
    }

    accessToken = () => {
      let token = store.get('oktaaccesstoken');
      return token;  
    };

    getAccessToken = (api, data, defaultConfig) => {
      if (data.password) {
        const password = CryptoJS.enc.Utf8.parse(data.password);
        const key = CryptoJS.enc.Utf8.parse(this.secretKey);
        const CryptoJSConfig = {
          keySize: 128 / 8,
          iv: key,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        }
        data.password = CryptoJS.AES.encrypt(password, key, CryptoJSConfig).toString()
      }
      const params = new URLSearchParams();
      params.append('grant_type', 'password');
      params.append('username', data.username);
      params.append('password', data.password);
      return this.tokenRequest(api, params, defaultConfig);
    }

    renewAccessToken = (api, data, defaultConfig) => {
      const params = new URLSearchParams();
      params.append('grant_type', 'refresh_token');
      params.append('refresh_token', data.refresh_token);
      return this.tokenRequest(api, params, defaultConfig);
    }

    tokenRequest = (api, data, defaultConfig) => {
      const config = {
        timeout: this.server.timeout,
        headers: {
          Authorization: 'Basic ' + btoa(this.clientId + ':' + this.clientSecret)
        },
        ...defaultConfig
      };
      if (this.server.cors) {
        config.crossdomain = true;
      }
      return axios.post(this.url + api, data, config);
    }

    defaultConfig = () => {
      const token = this.accessToken();
      const config = token ? { timeout: this.server.timeout, headers: { Authorization: `Bearer ${token}` } } : {  timeout: this.server.timeout};
      config.withCredentials = true;
      if (this.server.cors) {
        config.crossdomain = true;
      }
      return config
    }

    get = (api, data, defaultConfig) => {
      const token = this.accessToken();
      const config = { ...this.defaultConfig(), ...defaultConfig, params: data };
      return axios.get(this.url + api, config);
    }

    post = (api, data, defaultConfig) => {
      const config = { ...this.defaultConfig(), ...defaultConfig };
      return axios.post(this.url + api, data, config);
    }

    delete = (api, data, defaultConfig) => {
      const config = { ...this.defaultConfig(), ...defaultConfig };
      return axios.delete(this.url + api, data, config);
    }

    put = (api, data, defaultConfig) => {
      const config = { ...this.defaultConfig(), ...defaultConfig };
      return axios.put(this.url + api, data, config);
    }

    getExternal = (url, data, defaultConfig) => {
      const config = { ...this.defaultConfig(), ...defaultConfig, params: data };
      return axios.get(url, config);
    }

    postExternal = (url, data, defaultConfig) => {
      const config = { ...this.defaultConfig(), ...defaultConfig };
      return axios.post(url, data, config);
    }

    deleteExternal = (url, data, defaultConfig) => {
      const config = { ...this.defaultConfig(), ...defaultConfig };
      return axios.delete(url, data, config);
    }

    putExternal = (url, data, defaultConfig) => {
      const config = { ...this.defaultConfig(), ...defaultConfig };
      return axios.put(url, data, config);
    }

  };

export default new Ajax();



